import IWorkflowDefinition from '@/models/IWorkflowDefinition';
import AxiosInstance from 'axios';
import { IRFC7807ErrorResponse } from 'rey-web-toolkit';

export default class WorkflowService {
  public async get(key: string): Promise<{ result: IWorkflowDefinition | undefined; error: IRFC7807ErrorResponse | undefined }> {
    return AxiosInstance.get<IWorkflowDefinition>('api/WorkflowDefinition/' + key)
      .then((Response) => {
        // to be able to pass null-lists as reference to eg components map them to empty lists
        if (Response.data.componentInstances == null) {
          Response.data.componentInstances = [];
        }
        if (Response.data.workflowParameters == null) {
          Response.data.workflowParameters = [];
        }
        if (Response.data.workflowResults == null) {
          Response.data.workflowResults = [];
        }
        return {
          result: Response.data,
        } as { result: IWorkflowDefinition; error: IRFC7807ErrorResponse };
      })
      .catch((error: IRFC7807ErrorResponse) => {
        return {
          error: error,
        } as { result: IWorkflowDefinition; error: IRFC7807ErrorResponse };
      });
  }

  public async save(workflowComponent: IWorkflowDefinition) {
    await AxiosInstance.post(process.env.BASE_URL + 'api/WorkflowDefinition/' + workflowComponent.key, workflowComponent);
  }
}
