import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxSelection = _resolveComponent("DxSelection")!
  const _component_DxSearchPanel = _resolveComponent("DxSearchPanel")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxTreeList = _resolveComponent("DxTreeList")!
  const _component_ContentCard = _resolveComponent("ContentCard")!

  return (_openBlock(), _createBlock(_component_ContentCard, {
    title: "Vorhandene Variablen",
    class: "existingVariableTreeList"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DxTreeList, {
        id: "variableList",
        "data-structure": "tree",
        "items-expr": "children",
        "data-source": _ctx.resultVariableTree,
        "column-auto-width": true,
        "word-wrap-enabled": true,
        "show-borders": true,
        showColumnHeaders: false,
        autoExpandAll: true,
        onRowClick: _ctx.addParameterToComponent,
        onSelectionChanged: _ctx.resultParameterSelected
      }, {
        cell: _withCtx(({ data }) => [
          (!data.data.parentId)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("strong", null, _toDisplayString(data.data.displayName), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(data.data.displayName), 1))
        ]),
        default: _withCtx(() => [
          _createVNode(_component_DxSelection, { mode: "single" }),
          _createVNode(_component_DxSearchPanel, {
            visible: true,
            highlightSearchText: false,
            style: {"width":"100%"},
            placeholder: "Suche..."
          }),
          _createVNode(_component_DxColumn, { "cell-template": "cell" })
        ]),
        _: 1
      }, 8, ["data-source", "onRowClick", "onSelectionChanged"])
    ]),
    _: 1
  }))
}