import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "workflowComponentContainer" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "containerRow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddComponentButton = _resolveComponent("AddComponentButton")!
  const _component_WorkflowComponentContainer = _resolveComponent("WorkflowComponentContainer", true)!
  const _component_WorkflowComponent = _resolveComponent("WorkflowComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.containerDisplayName)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(_ctx.containerDisplayName), 1))
      : _createCommentVNode("", true),
    (_ctx.currentComponentContainerInstance === null && _ctx.getComponentInstances.length === 0)
      ? (_openBlock(), _createBlock(_component_AddComponentButton, {
          key: 1,
          componentInstance: null,
          addComponentPosition: _ctx.AddComponentPositionEnum.InitialWorkflowComponent,
          containerNumber: _ctx.containerNumber
        }, null, 8, ["addComponentPosition", "containerNumber"]))
      : _createCommentVNode("", true),
    (_ctx.currentComponentContainerInstance !== null && _ctx.getComponentInstances.length === 0)
      ? (_openBlock(), _createBlock(_component_AddComponentButton, {
          key: 2,
          componentInstance: _ctx.currentComponentContainerInstance,
          addComponentPosition: _ctx.AddComponentPositionEnum.InitialContainerComponent,
          containerNumber: _ctx.containerNumber
        }, null, 8, ["componentInstance", "addComponentPosition", "containerNumber"]))
      : _createCommentVNode("", true),
    (_ctx.getComponentInstances.length > 0)
      ? (_openBlock(), _createBlock(_component_AddComponentButton, {
          key: 3,
          componentInstance: _ctx.getComponentInstances[0],
          addComponentPosition: _ctx.AddComponentPositionEnum.BeforeSelectedComponent,
          containerNumber: _ctx.containerNumber
        }, null, 8, ["componentInstance", "addComponentPosition", "containerNumber"]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getComponentInstances, (componentInstance) => {
      return (_openBlock(), _createElementBlock("div", {
        key: componentInstance.id
      }, [
        _createVNode(_component_WorkflowComponent, {
          componentInstance: componentInstance,
          level: _ctx.level,
          foldLevel: _ctx.foldLevel
        }, {
          inComponent: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getContainers(componentInstance), (container) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "containerColumn",
                  key: container.containerNumber
                }, [
                  _createVNode(_component_WorkflowComponentContainer, {
                    currentComponentContainerInstance: componentInstance,
                    componentInstances: container.componentInstances,
                    containerNumber: container.containerNumber,
                    containerDisplayName: container.containerDisplayName,
                    level: _ctx.level + 1,
                    foldLevel: _ctx.foldLevel
                  }, null, 8, ["currentComponentContainerInstance", "componentInstances", "containerNumber", "containerDisplayName", "level", "foldLevel"])
                ]))
              }), 128))
            ])
          ]),
          afterComponent: _withCtx(() => [
            _createVNode(_component_AddComponentButton, {
              componentInstance: componentInstance,
              addComponentPosition: _ctx.AddComponentPositionEnum.AfterSelectedComponent,
              containerNumber: _ctx.containerNumber
            }, null, 8, ["componentInstance", "addComponentPosition", "containerNumber"])
          ]),
          _: 2
        }, 1032, ["componentInstance", "level", "foldLevel"])
      ]))
    }), 128)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}