import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dx_editing = _resolveComponent("dx-editing")!
  const _component_dx_custom_rule = _resolveComponent("dx-custom-rule")!
  const _component_dx_column = _resolveComponent("dx-column")!
  const _component_dx_data_grid = _resolveComponent("dx-data-grid")!
  const _component_content_card = _resolveComponent("content-card")!

  return (_openBlock(), _createBlock(_component_content_card, { title: "Workflow Parameter" }, {
    default: _withCtx(() => [
      _createVNode(_component_dx_data_grid, {
        id: "workflowParameterTable",
        dataSource: _ctx.dataSource,
        onEditorPreparing: _ctx.onEditorPreparing,
        onInitNewRow: _ctx.initNewRow
      }, {
        default: _withCtx(() => [
          _createVNode(_component_dx_editing, {
            "allow-adding": true,
            "allow-updating": true,
            "allow-deleting": true,
            mode: "cell"
          }),
          _createVNode(_component_dx_column, {
            caption: "Bezeichnung",
            "data-type": "string",
            "data-field": "propertyName",
            "show-editor-always": true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_dx_custom_rule, {
                message: "Parameter-Bezeichnung muss eindeutig pro Workflow sein",
                "validation-callback": _ctx.validateParameter
              }, null, 8, ["validation-callback"])
            ]),
            _: 1
          }),
          _createVNode(_component_dx_column, {
            caption: "Beschreibung",
            "data-type": "string",
            "data-field": "description",
            "show-editor-always": true
          }),
          _createVNode(_component_dx_column, {
            caption: "Mussfeld",
            "data-type": "boolean",
            "data-field": "isMandatory",
            "set-cell-value": _ctx.handleMandatoryParam,
            "show-editor-always": true
          }, null, 8, ["set-cell-value"]),
          _createVNode(_component_dx_column, {
            caption: "Standardwert",
            "data-type": "string",
            "data-field": "defaultValue",
            "show-editor-always": true
          })
        ]),
        _: 1
      }, 8, ["dataSource", "onEditorPreparing", "onInitNewRow"])
    ]),
    _: 1
  }))
}