
import IComponentInstance from '@/models/IComponentInstance';
import DxTextBox from 'devextreme-vue/text-box';
import DxTextArea from 'devextreme-vue/text-area';
import DxColorBox from 'devextreme-vue/color-box';
import DxCheckBox from 'devextreme-vue/check-box';
import DxTooltip from 'devextreme-vue/tooltip';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ContentCard } from 'rey-web-toolkit';
import DetailWorkflowComponentParameter from '@/views/workflowEditor/workflowComponents/DetailWorkflowComponentParameter.vue';
import ParameterDirectionEnum from '@/models/ParameterDirectionEnum';
import IParameterDefinition from '@/models/IParameterDefinition';

@Options({
  components: {
    DxTextBox,
    DxTextArea,
    DxColorBox,
    DxCheckBox,
    DxTooltip,
    ContentCard,
    DetailWorkflowComponent,
    DetailWorkflowComponentParameter,
  },
})
export default class DetailWorkflowComponent extends Vue {
  private internalParameterPrefix = 'DIP';
  @Prop()
  public detailComponent: IComponentInstance | undefined;

  public setNewComponentName(event: any) {
    if (!this.detailComponent) {
      return;
    }
    const oldValue = this.detailComponent?.title;
    const newValue = event.event.target.value;

    if (oldValue === newValue) {
      return;
    }
    this.detailComponent.title = newValue;

    const propertyNamesToUpdate = this.detailComponent.definition.componentValueDefinitions
      .filter((x) => x.useComponentInstanceNameAsParameterName)
      .map((x) => x.propertyName);

    this.detailComponent.results
      .filter((resultParameter) => propertyNamesToUpdate.some((x) => x === resultParameter.propertyName))
      .forEach((parameterToUpdate) => (parameterToUpdate.name = newValue));
  }

  public get getIComponentParameter() {
    return this.getPublicComponentValueDefinitions().filter((x) => x.parameterDirection == ParameterDirectionEnum.Input);
  }
  public get getIComponentResults() {
    return this.getPublicComponentValueDefinitions().filter((x) => x.parameterDirection == ParameterDirectionEnum.Output);
  }

  private getPublicComponentValueDefinitions(): IParameterDefinition[] {
    return (
      this.detailComponent?.definition.componentValueDefinitions.filter(
        (x) =>
          !x.defaultName.startsWith(this.internalParameterPrefix) &&
          (!x.globalNamespace || !x.globalNamespace?.startsWith(this.internalParameterPrefix)),
      ) ?? []
    );
  }
}
