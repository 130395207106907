
import IComponentInstance from '@/models/IComponentInstance';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import AddComponentPositionEnum from '@/models/AddComponentPositionEnum';
import WorkflowEditorStore from '@/store/workflowEditor/WorkflowEditorStore';
import IWorkflowEditorState from '@/store/workflowEditor/IWorkflowEditorState';
import DxSelectBox from 'devextreme-vue/select-box';

@Options({
  components: {
    DxSelectBox,
  },
})
export default class WorkflowComponentAddComponentButton extends Vue {
  public workflowEditorState: IWorkflowEditorState = WorkflowEditorStore.getState;
  public isFocused = false;
  public isOpened = false;

  @Prop({ required: true })
  public componentInstance!: IComponentInstance;

  @Prop({ required: true })
  public addComponentPosition!: AddComponentPositionEnum;

  @Prop({ required: true })
  public containerNumber!: number;

  public addComponent(event: { value: string }) {
    WorkflowEditorStore.addComponentInstance(event.value, this.componentInstance, this.addComponentPosition, this.containerNumber);
    this.isOpened = false;
  }

  get getSortedComponentDefinitions() {
    return this.workflowEditorState.componentDefinitions.sort((a, b) => a.name.localeCompare(b.name));
  }
}
