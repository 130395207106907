
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import WorkflowEditorOptionBar from '@/views/workflowEditor/OptionBar.vue';
import WorkflowEditorCanvas from '@/views/workflowEditor/WorkflowEditorCanvas.vue';
import WorkflowEditorSideBoard from './workflowEditor/SideBoard.vue';
import WorkflowComponent from './workflowEditor/workflowComponents/WorkflowComponent.vue';
import ExistingVariablesComponent from './workflowEditor/workflowComponents/ExistingVariablesComponent.vue';
import DetailWorkflowComponent from './workflowEditor/workflowComponents/DetailWorkflowComponent.vue';
import WorkflowEditorStore from '@/store/workflowEditor/WorkflowEditorStore';
import { AlertService, ContentCard, Titlebar } from 'rey-web-toolkit';
import { Options, Vue } from 'vue-class-component';
import WorkflowComponentContainer from './workflowEditor/workflowComponents/WorkflowComponentContainer.vue';
import IComponentInstance from '@/models/IComponentInstance';
import IWorkflowEditorState from '@/store/workflowEditor/IWorkflowEditorState';
import AddComponentPositionEnum from '@/models/AddComponentPositionEnum';
import AddComponentButton from './workflowEditor/workflowComponents/AddComponentButton.vue';
import DxPopup from 'devextreme-vue/popup';
import DxValidationGroup from 'devextreme-vue/validation-group';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import DxButton from 'devextreme-vue/button';
import { DxButtonGroup } from 'devextreme-vue/button-group';
import ValidationGroup from 'devextreme/ui/validation_group';
import { DxLoadIndicator } from 'devextreme-vue/load-indicator';
import { confirm } from 'devextreme/ui/dialog';
import { loadMessages, locale } from 'devextreme/localization';
import deMessages from 'devextreme/localization/messages/de.json';
import { Container } from 'typescript-ioc';
import axios from 'axios';
import ComponentTypeEnum from '@/models/ComponentTypeEnum';
import WorkflowParameters from '@/views/workflowEditor/workflowComponents/WorkflowParameters.vue';
import WorkflowResults from '@/views/workflowEditor/workflowComponents/WorkflowResults.vue';
import IWorkflowParameterDefinition from '@/models/IWorkflowParameterDefintion';
import FeatureFlagService from '@/services/FeatureFlagService';
import DxValidator, { DxRequiredRule } from 'devextreme-vue/validator';
import { DxValidationSummary } from 'devextreme-vue';
import { Watch } from 'vue-property-decorator';
import { ref } from 'vue';

@Options({
  components: {
    WorkflowResults,
    WorkflowParameters,
    WorkflowEditorOptionBar,
    WorkflowEditorCanvas,
    WorkflowEditorSideBoard,
    WorkflowComponent,
    ExistingVariablesComponent,
    DetailWorkflowComponent,
    Titlebar,
    WorkflowComponentContainer,
    AddComponentButton,
    DxPopup,
    DxValidationGroup,
    DxLoadIndicator,
    DxToolbar,
    DxItem,
    DxButton,
    DxButtonGroup,
    onBeforeRouteLeave,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    ContentCard,
    ref,
  },
})
export default class WorkflowEditor extends Vue {
  private alertService = Container.get(AlertService);

  public featureFlagService = Container.get<FeatureFlagService>(FeatureFlagService);

  public AddComponentPositionEnum = AddComponentPositionEnum;
  public workflowEditorState: IWorkflowEditorState = WorkflowEditorStore.getState;
  public workflowEditorStore = WorkflowEditorStore;
  public isSavePopupVisible = false;
  public isResetPopupVisible = false;
  public useAsWorkflowMethod = this.workflowEditorState.currentWorkflowDefinition?.canBeMethod;
  public displayWorkflowParameterAndResults = false;
  public foldLevel = 1;

  public isValid = true;
  public workflowComponentContainerStyle = 'none';

  public async beforeCreate() {
    document.title = 'System - Workflow Editor';
  }

  public beforeMount() {
    onBeforeRouteLeave(() => {
      if (!this.isWorkflowModified) {
        return true;
      }

      return confirm(
        '<p>Der Workflow hat noch ungespeicherte Änderungen.</p><p>Alle nicht gespeicherten Änderungen gehen beim Verlassen verloren.</p>',
        'Editor wirklich verlassen?',
      );
    });
    let paramKey: string = this.getWorkflowKey();
    WorkflowEditorStore.load(paramKey);
  }

  created() {
    document.addEventListener('click', this.onInputParameterEvent);
    document.addEventListener('keyup', this.onInputParameterEvent);
    this.translate();
  }

  beforeDestroy() {
    document.removeEventListener('click', this.onInputParameterEvent);
    document.removeEventListener('keyup', this.onInputParameterEvent);
  }

  public updateFoldLevel(increaseBy: number) {
    const newFoldLevel = this.foldLevel + increaseBy;

    this.foldLevel = newFoldLevel;
    // setting the same value to foldLevel would NOT trigger the watcher, so we use $nextTick to do the trick
    if (newFoldLevel < 0) {
      this.$nextTick(() => (this.foldLevel = 0));
      return;
    }
    if (newFoldLevel > 5) {
      this.$nextTick(() => (this.foldLevel = 5));
    }
  }

  public toggleUseAsWorkflowMethod(e: any) {
    this.useAsWorkflowMethod = !this.useAsWorkflowMethod;
    if (this.workflowEditorStore.getState.currentWorkflowDefinition) {
      this.workflowEditorStore.getState.currentWorkflowDefinition.canBeMethod =
        !this.workflowEditorStore.getState.currentWorkflowDefinition.canBeMethod;
    }

    this.toggleButtonClasses(e);
  }

  public toggleWorkflowParameterAndResult(e: any) {
    this.displayWorkflowParameterAndResults = !this.displayWorkflowParameterAndResults;
    this.toggleButtonClasses(e);
  }

  public toggleButtonClasses(e: any) {
    e.itemElement.classList.toggle('dx-item-selected');
    e.itemElement.classList.toggle('dx-button-normal');
    e.itemElement.classList.toggle('dx-button-default');
  }

  public getWorkflowKey(): string {
    const route = useRoute();
    let keyParam = '';
    if (Array.isArray(route.params.key)) {
      keyParam = route.params.key[0];
    } else {
      keyParam = route.params.key;
    }
    return keyParam;
  }

  public isWorkflowLoaded(key: string) {
    return WorkflowEditorStore.isWorkflowLoaded(key);
  }

  public async openSavePopup() {
    if (!this.areEditorInputsValid()) {
      this.alertService.add({
        type: 'danger',
        message: 'Der Workflow kann nicht gespeichert werden solange er ungültige Eingaben enthält.',
        autoCloseInMs: 5000,
      });
      return;
    }
    if (
      await confirm(
        '<p>Möchten Sie diesen Workflow wirklich speichern?</p><p>Alle Workflows, die dieses Template verwenden, werden neu gestartet!</p>',
        'Workflow speichern?',
      )
    ) {
      this.save();
    }
  }

  public save() {
    WorkflowEditorStore.save();
  }

  public async openResetPopup() {
    if (
      await confirm(
        '<p>Möchten Sie diesen Workflow wirklich auf den zuletzt gespeicherten Stand zurücksetzen?</p>',
        'Workflow zurücksetzen?',
      )
    ) {
      this.reset();
    }
  }

  public async executeTestrun() {
    axios.post('/odata/WorkflowInstance', {
      workflowDefinitionKey: this.workflowEditorState.currentWorkflowDefinition?.key,
      workflowInstanceType: 'Single',
      sourcePlugin: 'Core',
      sourceKey: 'Core.Testrun',
      sourceName: 'Core.Testrun',
      constants: '',
    });
    (this.$refs['execute'] as any).$el.classList.toggle('dx-button-normal');
    (this.$refs['execute'] as any).$el.classList.toggle('dx-button-default');
    setTimeout(() => {
      (this.$refs['execute'] as any).$el.classList.toggle('dx-button-normal');
      (this.$refs['execute'] as any).$el.classList.toggle('dx-button-default');
    }, 1000);
  }

  public reset() {
    WorkflowEditorStore.reset();
  }

  public get isWorkflowModified(): boolean {
    return WorkflowEditorStore.isWorkflowModified;
  }

  public get componentInstances(): IComponentInstance[] {
    return WorkflowEditorStore.getState.currentWorkflowDefinition?.componentInstances ?? [];
  }

  public get workflowParameters(): IWorkflowParameterDefinition[] {
    return WorkflowEditorStore.getState.currentWorkflowDefinition?.workflowParameters ?? [];
  }

  public get workflowTitle(): string {
    return WorkflowEditorStore.getState.currentWorkflowDefinition?.title ?? '';
  }

  public get selectedComponentInstance(): IComponentInstance | null {
    return WorkflowEditorStore.getState.selectedComponentInstance;
  }

  public onInputParameterEvent(event: Event) {
    const target = event.target as HTMLInputElement;

    if (target?.classList?.contains('parameterInput') ?? false) {
      this.workflowEditorState.parameterInput = {
        focus: target,
        positionStart: target.selectionStart,
        positionEnd: target.selectionEnd,
      };
      this.workflowEditorState.workflowResultValueInput = null;
    } else if (target?.classList?.contains('resultValueInput') ?? false) {
      this.workflowEditorState.workflowResultValueInput = {
        focus: target,
        positionStart: target.selectionStart,
        positionEnd: target.selectionEnd,
      };
      this.workflowEditorState.parameterInput = null;
    }
  }

  public onAddParameterToComponent(parameterFullName: string) {
    if (parameterFullName && parameterFullName !== '') {
      const parameterInputFocus = this.workflowEditorState.parameterInput?.focus;
      const workflowResultInputFocus = this.workflowEditorState.workflowResultValueInput?.focus;
      if (parameterInputFocus) {
        const componentInstance = this.workflowEditorState.currentWorkflowDefinition?.componentInstances.find(
          (x) => x.id == parameterInputFocus.getAttribute('componentinstanceid'),
        );
        const parameter = componentInstance?.parameters.find((x) => x.name == parameterInputFocus.getAttribute('parametername'));
        if (!parameter) {
          return;
        }
        const originalStr = this.workflowEditorStore.getDisplayVersionOfParameterValue(parameter);

        const selectionStart = this.workflowEditorState.parameterInput?.positionStart as number;
        const beforeStr = originalStr.substring(0, selectionStart);
        const afterStr = originalStr.substring(selectionStart, originalStr.length);
        const strToPut = '%' + parameterFullName + '%';
        this.workflowEditorStore.setParameterValue(beforeStr + strToPut + afterStr, parameter);
        parameterInputFocus.focus();
        if (this.workflowEditorState.parameterInput) {
          this.workflowEditorState.parameterInput.positionStart = selectionStart + strToPut.length;
        }
      } else if (workflowResultInputFocus) {
        const resultParameter = this.workflowEditorState.currentWorkflowDefinition?.workflowResults.find(
          (x) => x.id == workflowResultInputFocus.getAttribute('resultparameterid'),
        );
        if (!resultParameter) {
          return;
        }
        const originalStr = this.workflowEditorStore.getDisplayVersionOfResultParameterValue(resultParameter);

        const selectionStart = this.workflowEditorState.workflowResultValueInput?.positionStart as number;
        const beforeStr = originalStr.substring(0, selectionStart);
        const afterStr = originalStr.substring(selectionStart, originalStr.length);
        const strToPut = '%' + parameterFullName + '%';
        this.workflowEditorStore.setResultParameterValue(beforeStr + strToPut + afterStr, resultParameter);
        workflowResultInputFocus.focus();
        if (this.workflowEditorState.workflowResultValueInput) {
          this.workflowEditorState.workflowResultValueInput.positionStart = selectionStart + strToPut.length;
        }
      }
    }
  }

  private translate(): void {
    loadMessages(deMessages);
    locale('de');
  }

  public get getComponentInstances(): IComponentInstance[] {
    return this.workflowEditorState.currentWorkflowDefinition?.componentInstances.filter((x) => x.workflowParent?.parentId == null) ?? [];
  }

  @Watch('getComponentInstances')
  public onChangedGetComponentInstances() {
    this.revalidateComponentInstanceSize();
  }

  public areEditorInputsValid(): boolean {
    const validationResult = this.validationGroupEditor?.validate();
    const customValidationResult = (this.$refs['componentSizeValidator'] as DxValidator)?.instance?.validate();

    this.isValid = (validationResult?.isValid && customValidationResult?.isValid) ?? false;
    return this.isValid;
  }

  public get validationGroupEditor(): ValidationGroup | undefined {
    return (this.$refs?.validationGroupEditorRef as DxValidationGroup)?.instance;
  }

  public validationCallbacks = [];
  public validateComponentInstanceSize = {
    getValue: () => {
      return this.getComponentInstances.length > 0;
    },
    applyValidationResults: (e: any) => {
      this.workflowComponentContainerStyle = e.isValid ? 'none' : '1px solid red';
    },
    validationRequestsCallbacks: this.validationCallbacks,
  };
  public revalidateComponentInstanceSize = () => {
    // eslint-disable-next-line @typescript-eslint/ban-types
    this.validationCallbacks.forEach((func: Function) => {
      func();
    });
  };

  public get containsTriggerComponent(): boolean {
    return (
      this.workflowEditorState.currentWorkflowDefinition?.componentInstances.some(
        (x) => x.definition.componentType === ComponentTypeEnum.Trigger,
      ) ?? false
    );
  }

  public get containsWorkflowComponent(): boolean {
    return (
      this.workflowEditorState.currentWorkflowDefinition?.componentInstances.some(
        (x) => x.definition.componentType === ComponentTypeEnum.WorkflowMethod,
      ) ?? false
    );
  }
}
