
import { Options, Vue } from 'vue-class-component';
import { ContentCard, SmartTableV2 } from 'rey-web-toolkit';
import { DxColumn, DxCustomRule, DxDataGrid, DxEditing } from 'devextreme-vue/data-grid';
import DataSource from 'devextreme/data/data_source';
import IWorkflowParameterDefinition from '@/models/IWorkflowParameterDefintion';
import ArrayStore from 'devextreme/data/array_store';
import Guid from 'devextreme/core/guid';
import { Container } from 'typescript-ioc';
import FeatureFlagService from '@/services/FeatureFlagService';
import IWorkflowEditorState from '@/store/workflowEditor/IWorkflowEditorState';
import WorkflowEditorStore from '@/store/workflowEditor/WorkflowEditorStore';

@Options({
  components: {
    SmartTableV2,
    DxColumn,
    DxDataGrid,
    DxEditing,
    DxCustomRule,
    ContentCard,
  },
})
export default class WorkflowParameters extends Vue {
  public featureFlagService = Container.get<FeatureFlagService>(FeatureFlagService);
  public workflowEditorState: IWorkflowEditorState = WorkflowEditorStore.getState;
  public dataSource = new DataSource({
    store: new ArrayStore({
      key: 'id',
      data: this.workflowParameters,
      onInserting: (values: IWorkflowParameterDefinition) => (values.id = new Guid().toString()),
    }),
  });

  get workflowParameters(): IWorkflowParameterDefinition[] {
    return this.workflowEditorState.currentWorkflowDefinition?.workflowParameters ?? [];
  }

  public onEditorPreparing(e: any) {
    if (e.dataField === 'defaultValue' && e.parentType === 'dataRow') {
      // disable field `defaultValue` if it's a mandatory-filed
      e.editorOptions.disabled = e.row.data && e.row.data.isMandatory;
    }
  }

  public handleMandatoryParam(newData: IWorkflowParameterDefinition, isMandatory: boolean) {
    newData.isMandatory = isMandatory;
    if (isMandatory) {
      // reset `defaultValue` if we change it to a mandatory-param
      newData.defaultValue = '';
    }
  }

  public validateParameter(e: any) {
    return !this.workflowParameters
      // do not compare by itself
      .filter((p: IWorkflowParameterDefinition) => p.id !== e.data.id)
      .some((p: IWorkflowParameterDefinition) => p.propertyName === e.value);
  }
  public initNewRow(e: { data: IWorkflowParameterDefinition }) {
    e.data.isMandatory = true;
  }
}
