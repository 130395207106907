import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxTextBox = _resolveComponent("DxTextBox")!
  const _component_DxTextArea = _resolveComponent("DxTextArea")!
  const _component_DxColorBox = _resolveComponent("DxColorBox")!
  const _component_DxCheckBox = _resolveComponent("DxCheckBox")!
  const _component_DetailWorkflowComponentParameter = _resolveComponent("DetailWorkflowComponentParameter")!
  const _component_ContentCard = _resolveComponent("ContentCard")!

  return (_ctx.detailComponent)
    ? (_openBlock(), _createBlock(_component_ContentCard, {
        key: 0,
        title: _ctx.detailComponent.title,
        class: "componentDetailWrapper"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_DxTextBox, {
            class: "mb-3",
            labelMode: "floating",
            label: "Name",
            id: "componentName",
            value: _ctx.detailComponent.title,
            onChange: _ctx.setNewComponentName
          }, null, 8, ["value", "onChange"]),
          _createVNode(_component_DxTextArea, {
            class: "mb-3",
            labelMode: "floating",
            label: "Anmerkung",
            id: "componentRemark",
            modelValue: _ctx.detailComponent.remark,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.detailComponent.remark) = $event)),
            autoResizeEnabled: true,
            "max-height": "200px"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_DxTextBox, {
            class: "mb-3",
            labelMode: "floating",
            label: "Komponente",
            id: "componentDefinitionTitle",
            modelValue: _ctx.detailComponent.definition.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.detailComponent.definition.name) = $event)),
            readOnly: true
          }, null, 8, ["modelValue"]),
          _createVNode(_component_DxTextArea, {
            class: "mb-3",
            labelMode: "floating",
            label: "Beschreibung",
            id: "componentDefinitionDescription",
            modelValue: _ctx.detailComponent.definition.description,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.detailComponent.definition.description) = $event)),
            readOnly: true,
            autoResizeEnabled: true,
            "max-height": "200px"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_DxColorBox, {
            class: "mb-3",
            labelMode: "",
            label: "Farbe",
            id: "componentDefinitionColor",
            modelValue: _ctx.detailComponent.definition.color,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.detailComponent.definition.color) = $event)),
            readOnly: true
          }, null, 8, ["modelValue"]),
          _createVNode(_component_DxCheckBox, {
            class: "mb-3",
            labelMode: "static",
            text: "Kontextpersistierung",
            id: "componentDefinitionStoreContext",
            modelValue: _ctx.detailComponent.definition.persistResult,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.detailComponent.definition.persistResult) = $event)),
            readOnly: false
          }, null, 8, ["modelValue"]),
          (_ctx.getIComponentParameter)
            ? (_openBlock(), _createBlock(_component_DetailWorkflowComponentParameter, {
                key: 0,
                parameter: _ctx.getIComponentParameter,
                title: "Komponenten Parameter:"
              }, null, 8, ["parameter"]))
            : _createCommentVNode("", true),
          (_ctx.getIComponentResults)
            ? (_openBlock(), _createBlock(_component_DetailWorkflowComponentParameter, {
                key: 1,
                parameter: _ctx.getIComponentResults,
                title: "Komponenten Resultate:"
              }, null, 8, ["parameter"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}