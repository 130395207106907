
import { Options, Vue } from 'vue-class-component';
import DxTreeList, { DxButton, DxColumn, DxLookup, DxSearchPanel, DxSelection } from 'devextreme-vue/tree-list';
import { Emit, Prop } from 'vue-property-decorator';
import IComponentInstance from '@/models/IComponentInstance';
import { ContentCard } from 'rey-web-toolkit';
import WorkflowEditorStore from '@/store/workflowEditor/WorkflowEditorStore';
import { EventObject } from 'devextreme/events/index';
import IWorkflowParameterDefinition from '@/models/IWorkflowParameterDefintion';
import IResultInstance from '@/models/IResultInstance';

@Options({
  components: {
    DxTreeList,
    DxSearchPanel,
    DxColumn,
    DxLookup,
    DxSelection,
    DxButton,
    ContentCard,
  },
})
export default class ExistingVariablesComponent extends Vue {
  public internalParameterPrefix = 'DIP';
  @Prop() public componentInstances: IComponentInstance[] = [];
  @Prop() public workflowParameter: IWorkflowParameterDefinition[] = [];
  @Prop() public workflowKey = '';

  get resultVariableTree(): {
    id: string;
    parentId: string | null;
    displayName: string;
    children: { id: string; parentId: string; displayName: string }[];
  }[] {
    if (this.componentInstances == null && this.workflowParameter == null) {
      return [];
    }
    let allParametersFlattened = this.componentInstances.flatMap((x) => x.results);
    let allPublicParametersFlattened = allParametersFlattened.filter(
      (x) => !x.name.startsWith(this.internalParameterPrefix) && !x.namespace.startsWith(this.internalParameterPrefix),
    );
    let workflowParametersAsResults = this.workflowParameter.map((p) => {
      return { propertyName: p.propertyName, name: p.propertyName, namespace: this.workflowKey } as IResultInstance;
    });
    allPublicParametersFlattened = workflowParametersAsResults.concat(allPublicParametersFlattened);
    let allParametersDistinct = allPublicParametersFlattened.filter(
      (v, i, a) => a.findIndex((x) => x.name === v.name && x.namespace === v.namespace) === i,
    );
    let allNamespacesDistinct = allParametersDistinct.map((x) => x.namespace).filter((v, i, a) => a.indexOf(v) === i);

    return allNamespacesDistinct.map((namespace) => ({
      id: namespace,
      parentId: null,
      displayName: WorkflowEditorStore.getDisplayVersionOfParameterNamespace(namespace),
      children: allParametersDistinct
        .filter((x) => x.namespace == namespace)
        .map((variable) => ({
          id: variable.namespace + '.' + variable.name,
          parentId: variable.namespace,
          displayName: variable.name,
        })),
    }));
  }
  @Emit('onAddParameterToComponent')
  public addParameterToComponent(event: EventObject): string {
    if (event.data.id.indexOf('.') >= 1) {
      return event.data.id;
    }
    return '';
  }
  @Emit('onResultParameterSelected')
  public resultParameterSelected(event: { selectedRowKeys: string[] }): string {
    return event.selectedRowKeys[0];
  }
}
