import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "componentParameterWrapper" }
const _hoisted_2 = ["onMouseover"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxTextBox = _resolveComponent("DxTextBox")!
  const _component_DxTooltip = _resolveComponent("DxTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", null, _toDisplayString(_ctx.title), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parameter, (componentValueDefinition) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "componentParameterItem",
        key: componentValueDefinition.key
      }, [
        _createElementVNode("div", {
          class: "parameterInfo",
          onMouseover: ($event: any) => (_ctx.showParameterTooltip(componentValueDefinition.key)),
          onMouseout: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hideParameterTooltip()))
        }, [
          _createVNode(_component_DxTextBox, {
            class: "mb-3",
            labelMode: "floating",
            label: "Name",
            id: 'componentParameterName' + componentValueDefinition.propertyName,
            modelValue: componentValueDefinition.defaultName,
            "onUpdate:modelValue": ($event: any) => ((componentValueDefinition.defaultName) = $event),
            readOnly: true
          }, null, 8, ["id", "modelValue", "onUpdate:modelValue"])
        ], 40, _hoisted_2),
        _createVNode(_component_DxTooltip, {
          visible: componentValueDefinition.key === _ctx.parameterTooltipId,
          "close-on-outside-click": false,
          target: '#componentParameterName' + componentValueDefinition.propertyName,
          position: 'top'
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(componentValueDefinition.defaultDescription), 1)
          ]),
          _: 2
        }, 1032, ["visible", "target"])
      ]))
    }), 128))
  ]))
}