
import ParameterDirectionEnum from '@/models/ParameterDirectionEnum';
import ComponentValueTypeEnum from '@/models/ComponentValueTypeEnum';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import DxTooltip from 'devextreme-vue/tooltip';
import DxList from 'devextreme-vue/list';
import DxSelectBox from 'devextreme-vue/select-box';
import { DxTextBox, DxButton as DxTextBoxButton } from 'devextreme-vue/text-box';
import WorkflowEditorStore from '@/store/workflowEditor/WorkflowEditorStore';
import IParameterInstance from '@/models/IParameterInstance';
import IParameterDefinition from '@/models/IParameterDefinition';
import OdataStore from 'devextreme/data/odata/store';
import { PluginStore } from 'rey-web-toolkit';

import DxValidator, { DxRequiredRule } from 'devextreme-vue/validator';
@Options({
  components: {
    DxToolbar,
    DxList,
    DxItem,
    DxTextBox,
    DxTooltip,
    DxTextBoxButton,
    DxValidator,
    DxRequiredRule,
    DxSelectBox,
  },
})
export default class WorkflowComponentParameter extends Vue {
  public ParameterDirectionEnum = ParameterDirectionEnum;
  public ComponentValueTypeEnum = ComponentValueTypeEnum;
  public parameterTooltipId = '';
  public odataDropdownDataStore: any | null = null;

  @Prop()
  public componentInstanceId!: string;

  @Prop()
  public parameterInstance!: IParameterInstance;

  @Prop()
  public parameterDefinition!: IParameterDefinition;

  @Prop()
  public dependentParameterValue!: string | undefined;

  public mounted() {
    this.updateOdataDropdownDataStoreIfNecessary();
  }

  @Watch('dependentParameterValue')
  public onDepenedentParameterValueChanged(newValue: string | undefined, oldValue: string | undefined) {
    if (newValue === oldValue) {
      return;
    }
    this.odataDropdownDataStore = null;
    this.parameterInstance.configurationValue = '';
    this.updateOdataDropdownDataStoreIfNecessary();
  }

  public showParameterTooltip(name: string, id: string) {
    this.parameterTooltipId = this.getParameterTooltipId(name, id);
  }

  public hideParameterTooltip() {
    this.parameterTooltipId = '';
  }

  public getParameterTooltipId(name: string, id: string): string {
    return name.replace(/\W/g, '_') + '_' + id.replace(/\s/g, '');
  }

  public getDisplayVersionOfParameterValue(): string {
    return WorkflowEditorStore.getDisplayVersionOfParameterValue(this.parameterInstance);
  }

  public setParameterValue(event: any) {
    event = event.event.target.value;
    WorkflowEditorStore.setParameterValue(event, this.parameterInstance);
  }

  public getParameterLabel(): string {
    if (this.parameterDefinition.isOptional === false) {
      return this.parameterInstance.name + '*';
    }
    return this.parameterInstance.name;
  }

  private updateOdataDropdownDataStoreIfNecessary() {
    if (this.parameterDefinition?.componentValueType == ComponentValueTypeEnum.DropdownWithOdataSource) {
      this.odataDropdownDataStore = this.getOdataDropDownOptions();
    }
  }

  public get isDisabled() {
    if (!this.dependentParameterValue) {
      return false;
    }

    return this.dependentParameterValue === '';
  }

  private getOdataDropDownOptions(): any {
    let odataPath = this.parameterDefinition.dropDownOdataPath ?? '';
    if (this.parameterDefinition?.dependsOnOtherProperty) {
      odataPath = odataPath.replaceAll('{' + this.parameterDefinition.dependsOnOtherProperty + '}', this.dependentParameterValue ?? '');
    }
    let odataBasePathAndQueryPart = odataPath.split('?');
    return {
      store: new OdataStore({
        url: PluginStore.getPluginUrl(this.parameterDefinition?.dropDownSourcePlugin) + odataBasePathAndQueryPart[0],
        key: 'key',
        keyType: 'Guid',
        version: 4,
        beforeSend: (e) => {
          // only add query parameters if it is a collection query and not if it is a single value query
          if (odataBasePathAndQueryPart.length > 1 && !e.url.endsWith(')')) {
            e.url = e.url + '?' + odataBasePathAndQueryPart[1];
          }
        },
      }),
      sort: this.parameterDefinition.dropDownDisplayValueSelector,
    };
  }
}
