import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dx_editing = _resolveComponent("dx-editing")!
  const _component_dx_custom_rule = _resolveComponent("dx-custom-rule")!
  const _component_dx_column = _resolveComponent("dx-column")!
  const _component_DxTextBox = _resolveComponent("DxTextBox")!
  const _component_dx_data_grid = _resolveComponent("dx-data-grid")!
  const _component_content_card = _resolveComponent("content-card")!

  return (_openBlock(), _createBlock(_component_content_card, { title: "Workflow Resultate" }, {
    default: _withCtx(() => [
      _createVNode(_component_dx_data_grid, {
        id: "workflowResultTable",
        dataSource: _ctx.dataSource
      }, {
        valueEditor: _withCtx(({ data }) => [
          _createVNode(_component_DxTextBox, {
            class: "resultValueInput",
            id: 'resultValue_' + data.data.id,
            "input-attr": { class: 'resultValueInput', resultparameterid: data.data.id },
            value: _ctx.getDisplayVersionOfResultParameterValue(data),
            onChange: (event) => _ctx.setResultParameterValue(event, data)
          }, null, 8, ["id", "input-attr", "value", "onChange"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_dx_editing, {
            "allow-adding": true,
            "allow-updating": true,
            "allow-deleting": true,
            mode: "cell"
          }),
          _createVNode(_component_dx_column, {
            caption: "Bezeichnung",
            "data-type": "string",
            "data-field": "propertyName",
            "show-editor-always": true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_dx_custom_rule, {
                message: "Resultat-Bezeichnung muss eindeutig pro Workflow sein",
                "validation-callback": _ctx.validateResult
              }, null, 8, ["validation-callback"])
            ]),
            _: 1
          }),
          _createVNode(_component_dx_column, {
            caption: "Beschreibung",
            "data-type": "string",
            "data-field": "description",
            "show-editor-always": true
          }),
          _createVNode(_component_dx_column, {
            caption: "Wert",
            "data-type": "string",
            "data-field": "value",
            "edit-cell-template": "valueEditor",
            "show-editor-always": true
          })
        ]),
        _: 1
      }, 8, ["dataSource"])
    ]),
    _: 1
  }))
}