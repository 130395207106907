
import IComponentInstance from '@/models/IComponentInstance';
import ParameterDirectionEnum from '@/models/ParameterDirectionEnum';
import ComponentValueTypeEnum from '@/models/ComponentValueTypeEnum';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import WorkflowEditorStore from '@/store/workflowEditor/WorkflowEditorStore';
import IParameterInstance from '@/models/IParameterInstance';
import IParameterDefinition from '@/models/IParameterDefinition';
import WorkflowComponentParameter from './WorkflowComponentParameter.vue';
import ComponentTypeEnum from '@/models/ComponentTypeEnum';

@Options({
  components: {
    DxToolbar,
    DxItem,
    WorkflowComponentParameter,
  },
})
export default class WorkflowComponent extends Vue {
  public ParameterDirectionEnum = ParameterDirectionEnum;
  public ComponentValueTypeEnum = ComponentValueTypeEnum;
  private internalParameterPrefix = 'DIP';
  public parameterTooltipId = '';
  public isOpened = false;

  @Prop()
  public componentInstance!: IComponentInstance;

  @Prop()
  public foldLevel = 0;

  @Prop()
  public level = 0;

  @Watch('foldLevel')
  public foldLevelChanged(newFoldLevel: number) {
    if (newFoldLevel > this.level) {
      this.isOpened = true;
    } else {
      this.isOpened = false;
    }
  }

  public created() {
    const isNewlyCreatedComponent = this.componentInstance.id === WorkflowEditorStore.getState.openedComponentInstance?.id;
    const isAnyFlowComponent =
      this.componentInstance.definition.componentType === ComponentTypeEnum.FlowCondition ||
      this.componentInstance.definition.componentType === ComponentTypeEnum.FlowConditionLight ||
      this.componentInstance.definition.componentType === ComponentTypeEnum.FlowLoopFor ||
      this.componentInstance.definition.componentType === ComponentTypeEnum.FlowLoopWhile ||
      this.componentInstance.definition.componentType === ComponentTypeEnum.FlowTryCatch;

    const isFirstLevel = this.level === 0;

    this.isOpened = isNewlyCreatedComponent || (isAnyFlowComponent && isFirstLevel);
  }

  public selectComponentClickHandler(componentInstance: IComponentInstance): void {
    if (WorkflowEditorStore.getState.selectedComponentInstance === componentInstance) {
      WorkflowEditorStore.getState.selectedComponentInstance = null;
    } else {
      WorkflowEditorStore.getState.selectedComponentInstance = componentInstance;
    }
    WorkflowEditorStore.getState.selectedComponentInstance = componentInstance;
  }

  public deleteComponent(componentInstanceToDelete: IComponentInstance): void {
    if (componentInstanceToDelete?.id !== undefined) {
      WorkflowEditorStore.removeComponentInstance(componentInstanceToDelete.id);
    }
    WorkflowEditorStore.getState.selectedComponentInstance = null;
  }

  public toggleIsOpened() {
    this.isOpened = !this.isOpened;
  }

  get componentTitle() {
    if (this.componentInstance?.title && this.componentInstance.title !== '') {
      return this.componentInstance.title;
    } else {
      return this.componentInstance?.definition.name;
    }
  }

  get getComponentParameters(): IParameterInstance[] {
    return this.componentInstance.parameters.filter(
      (x) => !x.name.startsWith(this.internalParameterPrefix) && !x.namespace.startsWith(this.internalParameterPrefix),
    );
  }

  public getParameterDefinition(parameter: IParameterInstance): IParameterDefinition | undefined {
    const definition = this.componentInstance.definition.componentValueDefinitions.find((x) => x.key == parameter.parameterDefinitionKey);
    if (definition) {
      return definition;
    }
    // backward compatibility for workflows where parameterDefinitionKey was not saved with paramterInstance
    return this.componentInstance.definition.componentValueDefinitions.find(
      (x: IParameterDefinition) => x.defaultName == parameter.name && x.parameterDirection === ParameterDirectionEnum.Input,
    );
  }

  public getDependentParameterValue(parameter: IParameterInstance): string | undefined {
    const currentParaemeterDefinition = this.getParameterDefinition(parameter);
    const dependentParameterDefinition = this.componentInstance.definition.componentValueDefinitions.find(
      (x) => x.propertyName == currentParaemeterDefinition?.dependsOnOtherProperty,
    );
    if (!dependentParameterDefinition) {
      return undefined;
    }

    return (
      this.componentInstance.parameters.find((x) => x.parameterDefinitionKey === dependentParameterDefinition.key)?.configurationValue ?? ''
    );
  }
}
