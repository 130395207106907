import { reactive } from 'vue';
import { Guid } from 'guid-typescript';
import IParameterDefinition from '@/models/IParameterDefinition';
import ParameterDirectionEnum from '@/models/ParameterDirectionEnum';
import IComponentInstance from '@/models/IComponentInstance';
import IWorkflowEditorState from './IWorkflowEditorState';
import ComponentDefinitionService from '@/services/ComponentDefinitionService';
// because IRFC7807ErrorResponse seems to be unused by eslint
// eslint-disable-next-line
import { AlertService, GenericErrorHandler, IRFC7807ErrorResponse } from 'rey-web-toolkit';
import { Container } from 'typescript-ioc';
import WorkflowService from '@/services/WorkflowService';
import AddComponentPositionEnum from '@/models/AddComponentPositionEnum';
import IParameterInstance from '@/models/IParameterInstance';
import IResultInstance from '@/models/IResultInstance';
import IComponentDefinition from '@/models/IComponentDefinition';
// because AxiosError seems to be unused by eslint
// eslint-disable-next-line
import axios, { AxiosError } from 'axios';
import IWorkflowResultDefinition from '@/models/IWorkflowResultDefintion';

class WorkflowEditorStore {
  componentDefinitionService: ComponentDefinitionService = Container.get(ComponentDefinitionService);
  workflowDefinitionService: WorkflowService = Container.get(WorkflowService);
  private ownWorkflowParametersTitle = 'Workflow Parameter';

  private state: IWorkflowEditorState = reactive({
    componentDefinitions: [],
    selectedComponentInstance: null,
    originalWorkflowDefinition: null,
    currentWorkflowDefinition: null,
    openedComponentInstance: null,
    parameterInput: null,
    workflowResultValueInput: null,
  });

  public async load(workflowDefintionKey: string): Promise<void> {
    this.state.componentDefinitions = [];
    this.state.selectedComponentInstance = null;
    this.state.originalWorkflowDefinition = null;
    this.state.currentWorkflowDefinition = null;
    this.state.openedComponentInstance = null;
    this.state.parameterInput = null;
    this.state.workflowResultValueInput = null;
    await Promise.all([this.loadComponentDefinitions(), this.loadWorkflowDefinition(workflowDefintionKey)]);
  }

  public isWorkflowLoaded(workflowKey: string): boolean {
    return this.state.originalWorkflowDefinition?.key === workflowKey;
  }

  public addComponentInstance(
    componentDefinitionKey: string,
    componentInstance: IComponentInstance | null,
    addComponentPosition: AddComponentPositionEnum,
    containerNumber: number,
  ) {
    const componentDefinition = this.state.componentDefinitions.find((x) => x.key === componentDefinitionKey);
    if (!componentDefinition) {
      return;
    }
    if (this.state.currentWorkflowDefinition == null) {
      return;
    }
    const newInstanceId = Guid.create().toString();
    const defaultTitle = this.getDefaultTitle(componentDefinition);
    const instanceToAdd: IComponentInstance = {
      definition: componentDefinition,
      id: newInstanceId,
      title: defaultTitle,
      remark: '',
      parameters: this.mapDefinitionToParameterInstance(
        componentDefinition.componentValueDefinitions.filter((x) => x.parameterDirection === ParameterDirectionEnum.Input),
        newInstanceId,
      ),
      results: this.mapDefinitionToResultInstance(
        componentDefinition.componentValueDefinitions.filter((x) => x.parameterDirection === ParameterDirectionEnum.Output),
        newInstanceId,
        defaultTitle,
      ),
      workflowSuccessor: { nextId: null },
      workflowParent: { parentId: null, containerNumber: 0 },
      persistResult: true,
    };
    switch (addComponentPosition) {
      case AddComponentPositionEnum.InitialWorkflowComponent:
        this.state.currentWorkflowDefinition.componentInstances.push(instanceToAdd);
        break;

      case AddComponentPositionEnum.BeforeSelectedComponent:
        instanceToAdd.workflowParent.parentId = componentInstance?.workflowParent.parentId ?? null;
        instanceToAdd.workflowParent.containerNumber = componentInstance?.workflowParent.containerNumber ?? 0;
        instanceToAdd.workflowSuccessor.nextId = componentInstance?.id ?? null;
        this.addComponentBeforeSelectedComponent(instanceToAdd, componentInstance);
        break;

      case AddComponentPositionEnum.AfterSelectedComponent:
        instanceToAdd.workflowParent.parentId = componentInstance?.workflowParent.parentId ?? null;
        instanceToAdd.workflowParent.containerNumber = componentInstance?.workflowParent.containerNumber ?? 0;
        this.addComponentAfterSelectedComponent(instanceToAdd, componentInstance);
        this.updateCurrentWorkflowSuccessorNextId(instanceToAdd, componentInstance);
        this.updatePreviousWorkflowSuccessorNextId(instanceToAdd);
        break;

      case AddComponentPositionEnum.InitialContainerComponent:
        instanceToAdd.workflowParent.parentId = componentInstance?.id ?? null;
        instanceToAdd.workflowParent.containerNumber = containerNumber;
        this.addComponentAfterSelectedComponent(instanceToAdd, componentInstance);
        break;
    }
    this.state.selectedComponentInstance = instanceToAdd;
    this.state.openedComponentInstance = instanceToAdd;
  }

  private getDefaultTitle(definition: IComponentDefinition): string {
    return this.getNameRecursive(definition, 0);
  }

  private getNameRecursive(definition: IComponentDefinition, currentIndex: number): string {
    const proposedName = currentIndex === 0 ? definition.name : definition.name + ' ' + currentIndex;
    const isNameAlreadyTaken = this.state.currentWorkflowDefinition?.componentInstances.some((x) => x.title === proposedName);

    if (isNameAlreadyTaken) {
      return this.getNameRecursive(definition, currentIndex + 1);
    }

    return proposedName;
  }

  public removeComponentInstance(componentDefinitionKey: string) {
    if (this.state.currentWorkflowDefinition == null) {
      return;
    }
    const children = this.state.currentWorkflowDefinition.componentInstances.filter(
      (x) => x.workflowParent.parentId == componentDefinitionKey,
    );
    children.forEach((x) => this.removeComponentInstance(x.id));

    const componentIndexToRemove = this.state.currentWorkflowDefinition.componentInstances.findIndex(
      (x) => x.id === componentDefinitionKey,
    );
    const previousComponent = this.state.currentWorkflowDefinition.componentInstances.find(
      (x) => x.workflowSuccessor.nextId === componentDefinitionKey,
    );
    const nextId = this.state.currentWorkflowDefinition.componentInstances[componentIndexToRemove].workflowSuccessor.nextId;
    const nextComponent = this.state.currentWorkflowDefinition.componentInstances.find((x) => x.id === nextId);
    if (previousComponent != null) {
      previousComponent.workflowSuccessor.nextId = nextComponent?.id ?? null;
    }

    this.state.currentWorkflowDefinition.componentInstances.splice(componentIndexToRemove, 1);
    if (this.state.currentWorkflowDefinition.componentInstances.length <= 0) {
      this.state.selectedComponentInstance = null;
    }
  }

  public mapDefinitionToParameterInstance(componentValueDefinitions: IParameterDefinition[], instanceId: string): IParameterInstance[] {
    return componentValueDefinitions.map((x) => ({
      parameterDefinitionKey: x.key,
      name: x.defaultName,
      namespace: x.globalNamespace ?? instanceId,
      configurationValue: x.defaultValue ?? '',
    }));
  }

  public mapDefinitionToResultInstance(
    componentValueDefinitions: IParameterDefinition[],
    newInstanceId: string,
    newInstanceName: string,
  ): IResultInstance[] {
    return componentValueDefinitions.map((x) => ({
      propertyName: x.propertyName,
      name: x.useComponentInstanceNameAsParameterName ? newInstanceName : x.defaultName,
      namespace: x.globalNamespace ?? newInstanceId,
      dropDownOptions: x.dropDownOptions,
    }));
  }

  private async loadComponentDefinitions() {
    try {
      const result = (await this.componentDefinitionService.fetch()).sort(this.sortByName);
      this.state.componentDefinitions = result;
    } catch (exception: IRFC7807ErrorResponse | any) {
      GenericErrorHandler.handleRFC7807Error(exception);
    }
  }

  private async loadWorkflowDefinition(key: string) {
    const result = await this.workflowDefinitionService.get(key);
    if (result.error) {
      GenericErrorHandler.handleRFC7807Error(result.error);
    }
    if (result.result) {
      this.state.currentWorkflowDefinition = result.result;
    }
    // Current and Original must be separated like this in order that they won't be the same after changes
    const originalResult = await this.workflowDefinitionService.get(key);
    if (originalResult.error) {
      GenericErrorHandler.handleRFC7807Error(originalResult.error);
    }
    if (originalResult.result) {
      this.state.originalWorkflowDefinition = originalResult.result;
    }
  }

  get getState(): IWorkflowEditorState {
    return this.state;
  }

  get getExistingVariables(): IParameterDefinition[] {
    if (this.state.currentWorkflowDefinition == null) {
      return [];
    }
    return this.state.currentWorkflowDefinition.componentInstances.flatMap((workflowComponentInstance: IComponentInstance) =>
      workflowComponentInstance.definition.componentValueDefinitions.filter(
        (valueDefinition) => valueDefinition.parameterDirection === ParameterDirectionEnum.Output,
      ),
    );
  }

  get isWorkflowModified(): boolean {
    if (
      JSON.stringify(this.state.currentWorkflowDefinition?.componentInstances) !==
      JSON.stringify(this.state.originalWorkflowDefinition?.componentInstances)
    ) {
      return true;
    }
    if (
      JSON.stringify(this.state.currentWorkflowDefinition?.workflowParameters) !==
      JSON.stringify(this.state.originalWorkflowDefinition?.workflowParameters)
    ) {
      return true;
    }
    if (
      JSON.stringify(this.state.currentWorkflowDefinition?.workflowResults) !==
      JSON.stringify(this.state.originalWorkflowDefinition?.workflowResults)
    ) {
      return true;
    }
    if (this.state.currentWorkflowDefinition?.canBeMethod !== this.state.originalWorkflowDefinition?.canBeMethod) {
      return true;
    }
    return false;
  }

  public updateNextIds(
    currentComponent: IComponentInstance,
    previousComponent: IComponentInstance | null,
    nextComponent: IComponentInstance | null,
  ) {
    if (previousComponent != null) {
      previousComponent.workflowSuccessor.nextId = currentComponent.id;
    }
    currentComponent.workflowSuccessor.nextId = nextComponent?.id ?? null;
  }

  public updatePreviousWorkflowSuccessorNextId(instanceToAdd: IComponentInstance) {
    if (this.state.currentWorkflowDefinition == null) {
      return;
    }
    const previousComponentIndex = this.state.currentWorkflowDefinition?.componentInstances?.indexOf(instanceToAdd) - 1;
    if (previousComponentIndex < 0) {
      return;
    }
    this.state.currentWorkflowDefinition.componentInstances[previousComponentIndex].workflowSuccessor.nextId = instanceToAdd.id;
  }

  public updateCurrentWorkflowSuccessorNextId(instanceToAdd: IComponentInstance, previousComponent: IComponentInstance | null) {
    if (this.state.currentWorkflowDefinition == null) {
      return;
    }
    instanceToAdd.workflowSuccessor.nextId = previousComponent?.workflowSuccessor?.nextId ?? null;
  }

  public async save() {
    if (!this.state.currentWorkflowDefinition) {
      return;
    }
    try {
      await this.workflowDefinitionService.save(this.state.currentWorkflowDefinition);
      if (this.state.originalWorkflowDefinition == null) {
        return;
      }

      this.state.originalWorkflowDefinition.componentInstances = JSON.parse(
        JSON.stringify(this.state.currentWorkflowDefinition?.componentInstances),
      );
      this.state.originalWorkflowDefinition.canBeMethod = this.state.currentWorkflowDefinition?.canBeMethod;
      this.state.originalWorkflowDefinition.workflowParameters = JSON.parse(
        JSON.stringify(this.state.currentWorkflowDefinition?.workflowParameters),
      );
      this.state.originalWorkflowDefinition.workflowResults = JSON.parse(
        JSON.stringify(this.state.currentWorkflowDefinition?.workflowResults),
      );

      Container.get(AlertService).add({ message: 'Das Template wurde gespeichert!', type: 'success', autoCloseInMs: 3000 });
    } catch (error: Error | AxiosError | any) {
      if (axios.isAxiosError(error) && error.response) {
        GenericErrorHandler.handleRFC7807Error(error.response.data);
      } else {
        Container.get(AlertService).add({ message: error.message, type: 'danger', autoCloseInMs: undefined });
      }
    }
  }

  public async reset() {
    if (this.state.currentWorkflowDefinition) {
      this.state.currentWorkflowDefinition = JSON.parse(JSON.stringify(this.state.originalWorkflowDefinition));
    }
  }

  private addComponentAfterSelectedComponent(instanceToAdd: IComponentInstance, instanceToAddAfter: IComponentInstance | null) {
    if (!this.state.currentWorkflowDefinition) {
      return;
    }
    if (!instanceToAddAfter) {
      return;
    }

    const selectedComponentInstanceIndex = this.state.currentWorkflowDefinition.componentInstances.indexOf(instanceToAddAfter);

    this.state.currentWorkflowDefinition.componentInstances.splice(selectedComponentInstanceIndex + 1, 0, instanceToAdd);
  }

  private addComponentBeforeSelectedComponent(instanceToAdd: IComponentInstance, instanceToAddBefore: IComponentInstance | null) {
    if (!this.state.currentWorkflowDefinition) {
      return;
    }
    if (!instanceToAddBefore) {
      return;
    }

    const selectedComponentInstanceIndex = this.state.currentWorkflowDefinition.componentInstances.indexOf(instanceToAddBefore);

    this.state.currentWorkflowDefinition.componentInstances.splice(selectedComponentInstanceIndex, 0, instanceToAdd);
  }

  public getDisplayVersionOfResultParameterValue(workflowResult: IWorkflowResultDefinition): string {
    let valueToDisplay = workflowResult?.value ?? '';
    this.state.currentWorkflowDefinition?.componentInstances.forEach((x) => {
      valueToDisplay = valueToDisplay.replaceAll(x.id, x.title);
    });
    if (this.state.currentWorkflowDefinition?.key) {
      valueToDisplay = valueToDisplay.replaceAll(this.state.currentWorkflowDefinition.key, this.ownWorkflowParametersTitle);
    }
    return valueToDisplay;
  }

  public getDisplayVersionOfParameterValue(parameter: IParameterInstance): string {
    let valueToDisplay = parameter.configurationValue;
    this.state.currentWorkflowDefinition?.componentInstances.forEach((x) => {
      valueToDisplay = valueToDisplay.replaceAll(x.id, x.title);
    });
    if (this.state.currentWorkflowDefinition?.key) {
      valueToDisplay = valueToDisplay.replaceAll(this.state.currentWorkflowDefinition.key, this.ownWorkflowParametersTitle);
    }
    return valueToDisplay;
  }

  public getDisplayVersionOfParameterNamespace(namespace: string): string {
    if (namespace === this.state.currentWorkflowDefinition?.key) {
      return this.ownWorkflowParametersTitle;
    }
    return this.state.currentWorkflowDefinition?.componentInstances.find((x) => x.id === namespace)?.title ?? namespace;
  }

  private sortByName(a: IComponentDefinition, b: IComponentDefinition): number {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  }

  public setParameterValue(data: string, parameter: IParameterInstance) {
    let valueToSet = data ? data : '';
    if (valueToSet !== '') {
      this.state.currentWorkflowDefinition?.componentInstances.forEach(
        (x) => (valueToSet = valueToSet.replaceAll('%' + x.title + '.', '%' + x.id + '.')),
      );
    }
    if (this.state.currentWorkflowDefinition?.key) {
      valueToSet = valueToSet.replaceAll('%' + this.ownWorkflowParametersTitle + '.', '%' + this.state.currentWorkflowDefinition.key + '.');
    }
    parameter.configurationValue = valueToSet;
  }

  public setResultParameterValue(data: string, workflowResult: IWorkflowResultDefinition) {
    let valueToSet = data ? data : '';
    if (valueToSet !== '') {
      this.state.currentWorkflowDefinition?.componentInstances.forEach(
        (x) => (valueToSet = valueToSet.replaceAll('%' + x.title + '.', '%' + x.id + '.')),
      );
    }
    if (this.state.currentWorkflowDefinition?.key) {
      valueToSet = valueToSet.replaceAll('%' + this.ownWorkflowParametersTitle + '.', '%' + this.state.currentWorkflowDefinition.key + '.');
    }
    workflowResult.value = valueToSet;
  }
}
const storeInstance = new WorkflowEditorStore();

export default storeInstance;
