export enum ComponentTypeEnum {
  Unknown = 0,
  Action = 1,
  Trigger = 2,
  WorkflowMethod = 3,
  FlowCondition = 20,
  FlowConditionLight = 21,
  FlowLoopFor = 22,
  FlowLoopWhile = 23,
  FlowTryCatch = 24,
}

export default ComponentTypeEnum;
