
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import IParameterDefinition from '@/models/IParameterDefinition';
import DxTextBox from 'devextreme-vue/text-box';
import DxTooltip from 'devextreme-vue/tooltip';

@Options({
  components: {
    DxTextBox,
    DxTooltip,
  },
})
export default class DetailWorkflowComponentParameter extends Vue {
  parameterTooltipId = '';

  @Prop()
  public parameter: IParameterDefinition[] | undefined;
  @Prop()
  public title = '';

  public showParameterTooltip(key: string) {
    this.parameterTooltipId = key;
  }
  public hideParameterTooltip() {
    this.parameterTooltipId = '';
  }
}
