
import IComponentInstance from '@/models/IComponentInstance';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import WorkflowEditorStore from '@/store/workflowEditor/WorkflowEditorStore';
import WorkflowComponent from './WorkflowComponent.vue';
import ComponentTypeEnum from '@/models/ComponentTypeEnum';
import AddComponentButton from './AddComponentButton.vue';
import AddComponentPositionEnum from '@/models/AddComponentPositionEnum';

@Options({
  components: {
    WorkflowComponent,
    AddComponentButton,
  },
})
export default class WorkflowComponentContainer extends Vue {
  public AddComponentPositionEnum = AddComponentPositionEnum;

  @Prop()
  public currentComponentContainerInstance: IComponentInstance | null = null;
  @Prop()
  public containerNumber = 0;
  @Prop()
  public containerDisplayName: string | null = null;

  @Prop()
  public foldLevel = 0;

  @Prop()
  public level = 0;

  @Prop({ required: true })
  public componentInstances: IComponentInstance[] = [];

  private getChildren(componentInstance: IComponentInstance, containerNumber: number): IComponentInstance[] {
    return (
      WorkflowEditorStore.getState.currentWorkflowDefinition?.componentInstances.filter(
        (x) => x.workflowParent.parentId == componentInstance.id && x.workflowParent.containerNumber == containerNumber,
      ) ?? []
    );
  }
  public get getComponentInstances(): IComponentInstance[] {
    return this.componentInstances;
  }
  public getContainers(
    componentInstance: IComponentInstance,
  ): { containerNumber: number; containerDisplayName: string; componentInstances: IComponentInstance[] }[] {
    const containers: {
      containerNumber: number;
      containerDisplayName: string;
      componentInstances: IComponentInstance[];
    }[] = [];
    switch (componentInstance.definition.componentType) {
      case ComponentTypeEnum.FlowCondition:
        containers.push({
          containerNumber: 0,
          containerDisplayName: 'Wenn Ja (true)',
          componentInstances: this.getChildren(componentInstance, 0),
        });
        containers.push({
          containerNumber: 1,
          containerDisplayName: 'Wenn Nein (false)',
          componentInstances: this.getChildren(componentInstance, 1),
        });
        break;
      case ComponentTypeEnum.FlowConditionLight:
      case ComponentTypeEnum.FlowLoopFor:
      case ComponentTypeEnum.FlowLoopWhile:
        containers.push({
          containerNumber: 0,
          containerDisplayName: '',
          componentInstances: this.getChildren(componentInstance, 0),
        });
        break;
      case ComponentTypeEnum.FlowTryCatch:
        containers.push({
          containerNumber: 0,
          containerDisplayName: 'Try',
          componentInstances: this.getChildren(componentInstance, 0),
        });
        containers.push({
          containerNumber: 1,
          containerDisplayName: 'Catch',
          componentInstances: this.getChildren(componentInstance, 1),
        });
        containers.push({
          containerNumber: 2,
          containerDisplayName: 'Finally',
          componentInstances: this.getChildren(componentInstance, 2),
        });
        break;
    }

    return containers;
  }
}
