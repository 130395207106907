
import { Options, Vue } from 'vue-class-component';
import { ContentCard, SmartTableV2 } from 'rey-web-toolkit';
import { DxColumn, DxCustomRule, DxDataGrid, DxEditing, DxForm } from 'devextreme-vue/data-grid';
import DataSource from 'devextreme/data/data_source';
import IWorkflowResultDefinition from '@/models/IWorkflowResultDefintion';
import ArrayStore from 'devextreme/data/array_store';
import Guid from 'devextreme/core/guid';
import { Container } from 'typescript-ioc';
import FeatureFlagService from '@/services/FeatureFlagService';
import IWorkflowEditorState from '@/store/workflowEditor/IWorkflowEditorState';
import WorkflowEditorStore from '@/store/workflowEditor/WorkflowEditorStore';
import { DxItem } from 'devextreme-vue/form';
import ExistingVariablesComponent from '@/views/workflowEditor/workflowComponents/ExistingVariablesComponent.vue';
import IComponentInstance from '@/models/IComponentInstance';
import IWorkflowParameterDefinition from '@/models/IWorkflowParameterDefintion';
import DxTextBox from 'devextreme-vue/text-box';

@Options({
  components: {
    ExistingVariablesComponent,
    SmartTableV2,
    DxColumn,
    DxDataGrid,
    DxEditing,
    DxTextBox,
    DxForm,
    DxItem,
    DxCustomRule,
    ContentCard,
  },
})
export default class WorkflowResults extends Vue {
  public workflowEditorState: IWorkflowEditorState = WorkflowEditorStore.getState;
  public featureFlagService = Container.get<FeatureFlagService>(FeatureFlagService);
  public dataSource = new DataSource({
    store: new ArrayStore({
      key: 'id',
      data: this.workflowResults,
      onInserting: (values: IWorkflowResultDefinition) => (values.id = new Guid().toString()),
    }),
  });
  public currentResultValueEditor = '';

  get workflowResults(): IWorkflowResultDefinition[] {
    return this.workflowEditorState.currentWorkflowDefinition?.workflowResults ?? [];
  }

  public get componentInstances(): IComponentInstance[] {
    return this.workflowEditorState.currentWorkflowDefinition?.componentInstances ?? [];
  }

  public get workflowParameters(): IWorkflowParameterDefinition[] {
    return this.workflowEditorState.currentWorkflowDefinition?.workflowParameters ?? [];
  }

  public get workflowKey(): string {
    return this.workflowEditorState.currentWorkflowDefinition?.key ?? '';
  }

  public componentVariableClicked(parameterFullName: string) {
    if (parameterFullName && parameterFullName !== '') {
      if (this.$refs.currentValueEditor) {
        const editor = this.$refs.currentValueEditor as any;
        const inputElement = editor.instance.element()?.firstChild?.firstChild?.firstChild as HTMLInputElement;

        const originalStr = inputElement.value; // we can not use `this.currentResultValueEditor` as it is not in sync during modification :/
        const selectionStart = this.workflowEditorState.workflowResultValueInput?.positionStart as number;
        const beforeStr = originalStr.substring(0, selectionStart);
        const afterStr = originalStr.substring(selectionStart, originalStr.length);
        const strToPut = '%' + parameterFullName + '%';
        this.currentResultValueEditor = beforeStr + strToPut + afterStr;
      }
    }
  }

  public setEditedValue(valueChangedEventArg: any, cellInfo: any) {
    cellInfo.setValue(valueChangedEventArg.value);
  }

  public validateResult(e: any) {
    return !this.workflowResults
      // do not compare by itself
      .filter((p: IWorkflowResultDefinition) => p.id !== e.data.id)
      .some((p: IWorkflowResultDefinition) => p.propertyName === e.value);
  }

  public getDisplayVersionOfResultParameterValue(workflowResult: { data: IWorkflowResultDefinition }): string {
    return WorkflowEditorStore.getDisplayVersionOfResultParameterValue(workflowResult.data);
  }

  public setResultParameterValue(event: any, workflowResult: { data: IWorkflowResultDefinition }) {
    let value = event.event.target.value;
    WorkflowEditorStore.setResultParameterValue(value, workflowResult.data);
  }
}
